<template>
    <div>
        <Intro></Intro>
        <Nav></Nav>
        <router-view></router-view>
        <Footer></Footer>
    </div>
</template>

<script>
import { Intro, Nav, Footer } from './components'
export default {
    name: 'App',
    components: { Intro, Nav, Footer },

    setup: () => {},
}
</script>

<style lang="scss">
html {
    height: 100vh;
}
#app {
    overflow: hidden;
    position: relative;
    min-height: 100vh;
    box-sizing: border-box;
    padding: $nav-height 0 $footer-height;
}

@media screen and (max-width: 639px) {
    #app {
        padding-top: 0;
    }
}
</style>
