<template>
    <div id="FloatingOctave">
        <div class="octave right"></div>
        <div class="octave left"></div>
    </div>
</template>

<style lang="scss" scoped>
#FloatingOctave {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;

    .octave {
        position: absolute;
        width: 36vh;
        height: 36vh;
        opacity: 0.5;
        background: url('~@/assets/Scene0/frontside.png') center / contain
            no-repeat;
        transition: 2s ease-in-out;
        @keyframes sizeChanging {
            0% {
                transform: translateX(random(30) + px)
                    translateY(random(30) + px);
            }
            20% {
                transform: translateX(random(30) + px)
                    translateY(random(30) + px);
            }
            40% {
                transform: translateX(random(30) + px)
                    translateY(random(30) + px);
            }
            60% {
                transform: translateX(random(30) + px)
                    translateY(random(30) + px);
            }
            80% {
                transform: translateX(random(30) + px)
                    translateY(random(30) + px);
            }
            100% {
                transform: translateX(random(30) + px)
                    translateY(random(30) + px);
            }
        }
        &.right {
            top: 5%;
            right: -16vh;
            animation: sizeChanging 10s alternate infinite ease-in-out;
        }
        &.left {
            bottom: 8%;
            left: -8vh;
            animation: sizeChanging 12s alternate infinite ease-in-out;
        }
    }
}
</style>
