<template>
    <div id="Nav">
        <ul class="main">
            <li
                v-for="(value, key) in navData"
                :key="'nav-item-' + key"
                :class="{ active: active === key, inactive: active !== key }"
            >
                <router-link :to="value.route">
                    <div class="init">{{ value.en }}</div>
                    <div class="hover">{{ value.ch }}</div>
                    <i :class="'iconfont icon-' + value.icon"></i>
                </router-link>
            </li>
        </ul>
    </div>
</template>

<script>
import { reactive, watch, computed, ref } from 'vue-demi'
import { useRoute } from 'vue-router'
export default {
    name: 'Nav',
    setup() {
        const navData = reactive({
            home: {
                active: false,
                route: { name: 'Home' },
                en: 'HOME',
                ch: '首頁',
                icon: 'home',
            },
            service: {
                active: false,
                route: { name: 'Service' },
                en: 'SERVICE',
                ch: '服務',
                icon: 'gear',
            },
            collection: {
                active: false,
                route: { name: 'CollectionIndex' },
                en: 'COLLECTION',
                ch: '作品',
                icon: 'case',
            },
            crew: {
                active: false,
                route: { name: 'Crew' },
                en: 'CREW',
                ch: '團隊',
                icon: 'service',
            },
        })

        let route = useRoute()
        let currRoute = computed(() => route.path.split('/')[1])
        const active = ref('home')
        watch(currRoute, (newVal) => {
            if (newVal !== '') active.value = currRoute.value
            else active.value = 'home'
        })

        return { navData, active }
    },
}
</script>

<style lang="scss" scoped>
/* <===================== PC VIEW =====================> */
@media screen and (min-width: 640px) {
    i.iconfont {
        display: none;
    }

    #Nav {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1000;

        width: 100vw;

        background-color: rgba(#fff, 0.95);
        box-shadow: $shadow-box;
    }

    .main {
        display: flex;
        max-width: 1440px;
        width: 90%;
        margin: 0 auto;

        li {
            position: relative;

            width: 140px;
            height: $nav-height;

            .init,
            .hover {
                position: absolute;
                left: 0;
                top: 0;

                width: 100%;
                height: 100%;

                color: $gray-2;
                text-align: center;
                font-size: 16px;
                font-weight: 400;
                line-height: $nav-height;
                letter-spacing: 0.02em;

                transition: 0.3s;
            }

            .init {
                transform: rotateX(0deg) translateZ(calc(#{$nav-height} / 2));
            }
            .hover {
                transform: rotateX(-90deg) translateZ(calc(#{$nav-height} / 2));
            }

            &.active {
                .init {
                    font-weight: 700;
                    text-decoration: underline;
                    text-underline-offset: 2px;
                }
            }

            &.inactive {
                cursor: pointer;
                transition: 0.3s;
                &:hover {
                    background-color: $minor-light;
                    .init {
                        transform: rotateX(90deg)
                            translateZ(calc(#{$nav-height} / 2));
                    }
                    .hover {
                        transform: rotateX(0deg)
                            translateZ(calc(#{$nav-height} / 2));
                    }
                }
            }
        }
    }
}
@media screen and (min-width: 960px) {
    .main {
        justify-content: flex-end;
    }
}
@media screen and (max-width: 959px) and (min-width: 640px) {
    .main {
        justify-content: center;
    }
}

/* <===================== MOBILE VIEW =====================> */
@media screen and (max-width: 639px) {
    .init,
    .hover {
        display: none;
    }

    #Nav {
        position: fixed;
        top: 0;
        right: 0;
        z-index: 1000;

        padding: 15px;

        .main {
            li {
                a {
                    display: block;
                    width: 40px;
                    height: 40px;
                    margin: 5px;

                    color: $gray-d;
                    text-align: center;
                    text-decoration: none;
                    line-height: 40px;

                    border-radius: 50%;
                    background-color: rgba(white, 0.7);
                    box-shadow: $glow-box;

                    user-select: none;
                    transition: 1s;
                }

                &.active {
                    a {
                        color: $gray-4;
                        background-color: white;
                    }
                }
            }
        }
    }
}
</style>
