import axios from 'axios'
import store from '../store'
import { ElMessage } from 'element-plus'

let baseURL = 'https://api.octave.vip/v1'

// 建立 axios 實例
const myAxios = axios.create({
    baseURL: baseURL,
    timeout: 5000,
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
    },
})

const err = (error) => {
    if (error.response) {
        let data = error.response.data
        const token = store.getters.token
        if (error.response.status == 401) {
            ElMessage.error(data.message)
            if (token) {
                store.commit('initIdentityInfos', {
                    role: 'guest',
                    access_token: null,
                })
                setTimeout(() => {
                    window.location.reload()
                }, 1500)
            }
        } else {
            ElMessage.error(data.message)
        }
        return Promise.reject(error)
    }
}

// request 攔截器
myAxios.interceptors.request.use(
    (config) => {
        const token = store.getters.token
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`
        }
        return config
    },
    (error) => {
        console.log(error)
        return Promise.reject(error)
    }
)

// response 攔截器
myAxios.interceptors.response.use((response) => {
    // console.log('<==========RESPONSE DATA==========>')
    // console.log(response.data)
    return response.data
}, err)

/*  GET  */

export function get(url, params) {
    // console.log(url)
    return new Promise((resolve, reject) => {
        myAxios
            .get(url, { params: params })
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

/*  POST  */

export function post(url, data, params) {
    // console.log(url)
    return new Promise((resolve, reject) => {
        myAxios
            .post(url, data, { params: params })
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

/*  PUT  */

export function put(url, data) {
    // console.log(url)
    return new Promise((resolve, reject) => {
        myAxios
            .put(url, data)
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

/*  DELETE  */

export function del(url, data) {
    // console.log(url)
    return new Promise((resolve, reject) => {
        myAxios
            .delete(url, { data })
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

/*  UPLOAD  */

export function upload(url, file) {
    // console.log(url)
    let data = new FormData()
    data.append('file', file)
    return new Promise((resolve, reject) => {
        myAxios
            .post(url, data)
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export default {
    get,
    post,
    put,
    del,
    upload,
}
