import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import AOS from 'aos'
import smoothscroll from 'smoothscroll-polyfill'

import './fonts/noto-sans-tc/noto-sans-tc.css'
import './fonts/glow-sans-tc/glow-sans-tc.css'
import './fonts/poppins-v19-latin/poppins.css'
import './fonts/iconfont/iconfont.css'
import './style/reset.css'
import 'aos/dist/aos.css'
import 'element-plus/dist/index.css'

createApp(App).use(store).use(router).use(ElementPlus).mount('#app')
AOS.init({ duration: 800 })
smoothscroll.polyfill()
