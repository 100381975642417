<template>
    <div id="Footer">Copyright ©{{ year }} Octave. All Rights Reserved.</div>
</template>

<script>
import { computed } from 'vue-demi'
export default {
    name: 'Footer',
    setup() {
        const year = computed(() => {
            return new Date().getFullYear()
        })

        return { year }
    },
}
</script>

<style lang="scss" scoped>
#Footer {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 100;

    width: 100vw;
    height: $footer-height;
    background-color: $gray-d;

    color: $gray-4;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    line-height: $footer-height;
    letter-spacing: 0.05em;
}
</style>
