import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
    },
    {
        path: '/service',
        name: 'Service',
        // lazy-loaded
        component: () => import('../views/Service.vue'),
    },
    {
        path: '/collection',
        name: 'Collection',
        // lazy-loaded
        component: () => import('../views/Collection.vue'),
        children: [
            {
                path: '',
                name: 'CollectionIndex',
                component: () =>
                    import('../views/Collection/CollectionIndex.vue'),
            },
            {
                path: 'project/:projectTitle',
                name: 'Project',
                component: () => import('../views/Collection/Project.vue'),
            },
            {
                path: 'course',
                name: 'Course',
                component: () => import('../views/Collection/Course.vue'),
            },
        ],
    },
    {
        path: '/crew',
        name: 'Crew',
        // lazy-loaded
        component: () => import('../views/Crew.vue'),
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes,
})

router.beforeEach(() => {
    window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
    })
})

export default router
