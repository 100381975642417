import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

const moduleIdentity = {
    state: {
        visited: false,
    },
    mutations: {
        visit(state) {
            state.visited = true
        },
    },
    actions: {},
    getters: {
        isVisited: (state) => {
            return state.visited
        },
    },
}

const PERSIST_PATHS = ['moduleIdentity']
export default createStore({
    state: {},
    mutations: {},
    actions: {},
    modules: { moduleIdentity },
    plugins: [
        createPersistedState({
            storage: window.sessionStorage,
            paths: PERSIST_PATHS,
        }),
    ],
})
